import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  public storedTheme = localStorage.getItem('theme') ?? 'light'
  public themeSelection: BehaviorSubject<string> = new BehaviorSubject<string>(this.storedTheme)

  constructor() {
    this.setTheme(this.getPreferredTheme())
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', () => {
      if (this.storedTheme !== 'light' && this.storedTheme !== 'dark') {
        this.setTheme(this.getPreferredTheme())
      }
    })
  }
  getPreferredTheme() {
    if (this.storedTheme) {
      return this.storedTheme
    } else {
      return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
    }
  }


  setTheme(theme: string) {
    if (theme === 'auto' && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      document.documentElement.setAttribute('data-bs-theme', 'dark')
      this.themeSelection.next('dark')
    } else {
      document.documentElement.setAttribute('data-bs-theme', theme)
      this.themeSelection.next(theme)
    }

    localStorage.setItem('theme', theme)
    this.storedTheme = theme
  }

  themeChanges(): Observable<string> {
    return this.themeSelection.asObservable()
  }
}